import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_DEV } from "@/__main__/constants.mjs";
import { setRoute } from "@/__main__/router.mjs";
import InteractiveMap from "@/game-eft/components/InteractiveMap.jsx";
import getMapName from "@/game-eft/utils/get-map-name.mjs";
import { getMapTilesUrl } from "@/game-eft/utils/get-map-tiles-url.mjs";
import useMapImages from "@/game-eft/utils/use-map-images.mjs";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { Timer } from "@/shared/Time.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Main() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const { getMapImage } = useMapImages();
  const liveGame = state.eft.liveGame;
  const map = liveGame?.gameStart?.location;
  const mapName = t(...getMapName(map));
  useEffect(() => {
    if (!IS_APP && !IS_DEV) setRoute("/eft");
  }, []);
  return (
    <SharedMatchLayout
      match={liveGame?.gameStart}
      title={t("fortnite:match.gameInProgress", "Game in progress")}
      image={getMapImage(map)}
      borderColor="var(--shade3)"
      underTitle={
        <div className="flex align-center gap-2 color-shade2">
          <div>{mapName}</div>
          {Boolean(liveGame?.gameStart?.start_time) && (
            <>
              ·
              <div>
                <Timer
                  startTime={liveGame?.gameStart?.start_time}
                  format={undefined}
                />
              </div>
            </>
          )}
        </div>
      }
    >
      {getMapTilesUrl(map) ? (
        <InteractiveMap map={map} />
      ) : (
        <div>
          <div className="type-large-title--bold">
            {t(
              "eft:match.noExtractionMap.title",
              "Interactive map not supported yet",
            )}
          </div>
          <div className="type-subtitle">
            {t(
              "eft:match.noExtractionMap.description",
              "You are either playing a practice raid, a new map or a map we don't support yet. We will support this map soon with extractions.",
            )}
          </div>
        </div>
      )}
    </SharedMatchLayout>
  );
}

// This is a app route not a web route, no title + description necessary
export function meta() {
  return {
    title: ["", ""],
    description: ["", ""],
  };
}
